/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

body,
html {
  font-size: 14px;
  height: 100%;
}

.mat-sidenav-content {
  background-color: #f4f4f4 !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

/*  Menu Bar Styles */
.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.active-link {
  background-color: white !important;
  /* margin-left: 0px !important; */
}

.active-link .mat-list-item-content {
  color: #007cba !important;
  font-weight: 600 !important;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 81%);
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
}

.ngx-pagination .current {
  background: #007cba !important;
}

.mat-tab-label {
  min-width: 110px !important;
  padding: 0 12px !important;
}

button:focus {
  outline: none !important;
}

/* FONT SIZE  */

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f16 {
  font-size: 16px;
}

.cp {
  cursor: pointer;
}

/* BUTTON STYLE */

.btn-type-two {
  background-color: #007cba;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 0;
  padding: 2px 10px;
}

.btn-type-two:hover {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}

.color-white {
  color: #fff !important;
}

.nav-link.active {
  border-color: transparent !important;
  border-bottom: 2px solid #3f51b5 !important;
}

.nav-tabs .nav-link {
  line-height: 2;
  background-color: white !important;
  border-top-left-radius: 6px !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 1px solid #dee2e6;
}

/* TABLE start */

.table td,
.table th {
  vertical-align: middle;
  background-color: white;
  word-break: break-word;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #7bc0c13b !important;
}

.tableInputStyles {
  border: none;
  width: 100%;
  text-align: left !important;
}

.tableInputStyles:focus-within {
  outline: none !important;
}

.firstCol {
  font-weight: 600;
}

/* TABLE end */

/* SCROLLBAR start */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* SCROLLBAR end */

.urlStyles {
  cursor: pointer;
  color: #007cba !important;
  text-decoration: none;
}

.urlStyles:hover {
  text-decoration: underline;
}

.card-header {
  background-color: black;
  border-bottom: none;
  color: white;
  padding-top: 12px;
  border-radius: 6px 6px 0 0 !important;
}

.emailPreview .p-dialog {
  width: 50vw !important;
}

.dropdown-item:active {
  background-color: #007cba !important;
}

/* SNACKBAR STYLE */

.mat-snack-bar-container {
  background: #007cba !important;
}

.mat-simple-snackbar-action {
  color: #fff !important;
}

.three {
  display: flex;
  align-items: center;
  justify-content: center;
}

.three i {
  font-size: 18px;
  cursor: pointer;
}

.fa-plus,
.fa-minus {
  color: #007cba;
}

.bs-datepicker-body table td span.selected {
  background-color: #007cba !important;
}

.bs-datepicker-head {
  background-color: #007cba !important;
}

.mat-step-icon-selected {
  background-color: #007cba !important;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: #007cba;
}

.ql-snow .ql-editor a {
  background-color: #007cba;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  padding: 11px 26px;
  cursor: pointer;
}

.ql-snow .ql-editor a:hover {
  border: 1px solid #000 !important;
  background-color: transparent !important;
  color: #000 !important;
}

.ql-snow .ql-editor ul,
li {
  word-break: break-word;
  font-family: var(--lender-sub-font);
  font-size: var(--lender-font-size-2);
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: var(--lender-input-text-color);
}

.p-field-checkbox {
  margin-bottom: 0px !important;
}

.p-field-checkbox>label {
  margin-left: 0px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #007cba !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

insurance-select>.p-float-label>label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 55px);
}

insurance-input>.p-float-label>label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}

.mobile-popup-screen .pop90 {
  width: 95vw !important;
}